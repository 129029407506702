import { render, staticRenderFns } from "./EventDetailDialog.vue?vue&type=template&id=73cdafb2&scoped=true&"
import script from "./EventDetailDialog.js?vue&type=script&lang=js&"
export * from "./EventDetailDialog.js?vue&type=script&lang=js&"
import style0 from "./EventDetailDialog.scss?vue&type=style&index=0&id=73cdafb2&lang=scss&scoped=true&"
import style1 from "./EventDetailDialog.vue?vue&type=style&index=1&id=73cdafb2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cdafb2",
  null
  
)

export default component.exports